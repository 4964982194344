import * as d3 from "d3";
import d3tip from 'd3-tip';

//d3.tip = d3tip;

(function ($) {
    'use strict';

    const colors = [
        '#f3a683', '#778beb', '#f7d794', '#cf6a87', '#e77f67', '#f5cd79', '#f19066', '#e15f41', '#546de5', '#786fa6', '#c44569', '#63cdda', '#f8a5c2', '#596275', '#ea8685', '#f78fb3', '#574b90', '#e66767', '#3dc1d3', '#303952'
    ];

    var getColorsForCategories = function (categories) {
        var selectedColors = {};

        for (var i = 0; i < categories.length; i++) {
            selectedColors[categories[i]] = colors[i % colors.length];
        }

        return selectedColors;
    };

    var initGenderDonutCharts = function () {
        $('.politikChart.genderChart').each(function () {
            c3.generate({
                bindto: this,
                data: {
                    json: $(this).data('stats'),
                    keys: {
                        value: ['m', 'f']
                    },
                    type: 'donut',
                    names: {
                        m: Translator.trans('charts.men'),
                        f: Translator.trans('charts.women')
                    },
                    colors: getColorsForCategories(['m', 'f'])
                },
                donut: {
                    title: Translator.trans('charts.gender.title'),
                    label: {
                        format: function (value, ratio) {
                            return value;
                        }
                    },
                }
            });
        });
    };

    var initAgeStackedBarCharts = function () {
        $('.politikChart.ageChart').each(function () {
            const data = $(this).data('stats').reduce((total, current) => {
                const i = current.age < 34 ? 0 : current.age < 46 ? 1 : 2;

                total[i].m = total[i].m + current.m;
                total[i].f = total[i].f + current.f;

                return total;
            }, [{ age: '33-', m: 0, f: 0 }, { age: '34 - 45', m: 0, f: 0 }, { age: '45+', m: 0, f: 0 }]);

            const container = d3.select(this);
            const width = Math.min(this.clientWidth, 350);
            const dimensions = { width: width, margin: { top: 20, right: 20, bottom: 50, left: 20 } };
            const graphWidth = width - dimensions.margin.left - dimensions.margin.right;
            const graphHeight = width - dimensions.margin.top - dimensions.margin.bottom;
            const svg = container.append('svg')
                .attr('width', width)
                .attr('height', width)
                .attr('style', 'display:block;margin:auto;width:initial;');

            const graph = svg.append('g')
                .attr('width', graphWidth)
                .attr('height', graphHeight)
                .attr('transform', `translate(${dimensions.margin.left}, ${dimensions.margin.top})`);
            const xAxisGroup = graph.append('g')
                .attr('transform', `translate(0, ${graphHeight})`);
            const yAxisGroup = graph.append('g');
            const y = d3.scaleLinear()
                .domain([0, d3.max(data, d => d.m + d.f)])
                .range([graphHeight, 0]);
            const x = d3.scaleBand()
                .domain(data.map(item => item.age))
                .range([0, graphWidth])
                .paddingInner(0.2)
                .paddingOuter(0.2);
            const rects = graph.selectAll('rect').data(data);

            const mrects = rects.enter()
                .append('rect')
                .attr('width', x.bandwidth)
                .attr("height", d => graphHeight - y(d.m))
                .attr('style', 'fill: rgb(243, 165, 131); cursor: pointer;')
                .attr('x', d => x(d.age))
                .attr('y', d => y(d.m))
                .on('mouseover', (d, i, n) => {
                    tipM.show(d, n[i]);
                    d3.select(n[i])
                        .transition().duration(100)
                        .attr('style', 'fill: rgb(121, 82, 66); cursor: pointer;');
                })
                .on('mouseout', (d, i, n) => {
                    tipM.hide();
                    d3.select(n[i])
                        .transition().duration(100)
                        .attr('style', 'fill: rgb(243, 165, 131); cursor: pointer;');
                });

            const frects = rects.enter()
                .append('rect')
                .attr('width', x.bandwidth)
                .attr("height", d => graphHeight - y(d.f))
                .attr('style', 'fill: rgb(119, 139, 235); cursor: pointer;')
                .attr('x', d => x(d.age))
                .attr('y', d => y(d.m + d.f))
                .on('mouseover', (d, i, n) => {
                    tipF.show(d, n[i]);
                    d3.select(n[i])
                        .transition().duration(100)
                        .attr('style', 'fill: rgb(60, 70, 120); cursor: pointer;');
                })
                .on('mouseout', (d, i, n) => {
                    tipF.hide();
                    d3.select(n[i])
                        .transition().duration(100)
                        .attr('style', 'fill: rgb(119, 139, 235); cursor: pointer;');
                });

            const xAxis = d3.axisBottom(x);
            const yAxis = d3.axisLeft(y)
                .ticks(3)
                .tickFormat(d => d);

            xAxisGroup.call(xAxis);
            yAxisGroup.call(yAxis);

            xAxisGroup.selectAll('text')
                .attr('transform', 'rotate(-40)')
                .attr('text-anchor', 'end');

            const tip = (key) => d3tip().html((d) => `<div style="border-radius:5px;background:white;padding: 10px;border:gray 1px solid;">${key === 'm' ? Translator.trans('charts.men') : Translator.trans('charts.women')}: ${d[key]}</div>`).offset([10, -10]);
            const tipM = tip('m');
            const tipF = tip('f');

            graph.call(tipM);
            graph.call(tipF);

            const redraw = () => {
                const width = Math.min(this.clientWidth, 350);
                const graphWidth = width - dimensions.margin.left - dimensions.margin.right;
                const graphHeight = width - dimensions.margin.top - dimensions.margin.bottom;

                svg
                    .attr('width', width)
                    .attr('height', width);
                graph
                    .attr('width', graphWidth)
                    .attr('height', graphHeight);
                xAxisGroup
                    .attr('transform', `translate(0, ${graphHeight})`);
                const y = d3.scaleLinear()
                    .domain([0, d3.max(data, d => d.m + d.f)])
                    .range([graphHeight, 0]);
                const x = d3.scaleBand()
                    .domain(data.map(item => item.age))
                    .range([0, graphWidth])
                    .paddingInner(0.2)
                    .paddingOuter(0.2);


                mrects
                    .attr('width', x.bandwidth)
                    .attr("height", d => graphHeight - y(d.m))
                    .attr('x', d => x(d.age))
                    .attr('y', d => y(d.m));

                frects
                    .attr('width', x.bandwidth)
                    .attr("height", d => graphHeight - y(d.f))
                    .attr('x', d => x(d.age))
                    .attr('y', d => y(d.m + d.f));
                const xAxis = d3.axisBottom(x);
                const yAxis = d3.axisLeft(y)
                    .ticks(3)
                    .tickFormat(d => d);

                xAxisGroup.call(xAxis);
                yAxisGroup.call(yAxis);
            };

            redraw();
            window.addEventListener("resize", redraw);
        });
    };

    var initCantonalYearlyStats = function () {
        $(function () {
            $('.politikChart.cantonYearlyStats').each(function () {
                var json = $(this).data('stats');
                var years = [];

                json.forEach(function (canton) {
                    Object.keys(canton).forEach(function (key) {
                        if (key >= 1999 && -1 === years.indexOf(key)) {
                            years.push(key);
                        }
                    });
                });
                years = years.sort(function (a, b) {
                    return parseInt(a) - parseInt(b);
                });
                c3.generate({
                    bindto: this,
                    data: {
                        json: json,
                        keys: {
                            x: 'abbr',
                            value: years
                        },
                        type: 'bar',
                        onclick: function (data) {
                            try {
                                var route = Routing.generate(
                                    'cantonal_list_canton_affairs',
                                    {
                                        'slug': json[data['index']]['abbr'].toLowerCase(),
                                        'year': data['id']
                                    }
                                );

                                document.location = route;
                            }
                            catch (e) { }
                        },
                        colors: getColorsForCategories(years)
                    },
                    axis: {
                        x: {
                            type: 'category'
                        }
                    },
                    padding: {
                        right: 30
                    }
                });
            });
        });
    };

    $(document).ready(function () {
        $(initGenderDonutCharts);
        $(initAgeStackedBarCharts);
        $(initCantonalYearlyStats);
    });
}(window.jQuery));
