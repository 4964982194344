import $ from 'jquery';


var $toggle = null;

(function() {
  $('html').addClass('perfect-scrollbar-off');
})();

var mobile_menu_visible = 0;

$(document).on('click', '.navbar-toggler', function() {
  $toggle = $(this);
  
  if (mobile_menu_visible == 1) {
    $('html').removeClass('nav-open');
  
    $('.close-layer').remove();
    setTimeout(function() {
      $toggle.removeClass('toggled');
    }, 400);
  
    mobile_menu_visible = 0;
  }
  else {
    setTimeout(function() {
      $toggle.addClass('toggled');
    }, 430);
  
    var $layer = $('<div class="close-layer"></div>');
  
    if ($('body').find('.main-panel').length != 0) {
      $layer.appendTo(".main-panel");
  
    }
    else if (($('body').hasClass('off-canvas-sidebar'))) {
      $layer.appendTo(".wrapper-full-page");
    }
  
    setTimeout(function() {
      $layer.addClass('visible');
    }, 100);
  
    $layer.click(function() {
      $('html').removeClass('nav-open');
      mobile_menu_visible = 0;
  
      $layer.removeClass('visible');
  
      setTimeout(function() {
        $layer.remove();
        $toggle.removeClass('toggled');
  
      }, 400);
    });
  
    $('html').addClass('nav-open');
    mobile_menu_visible = 1;
  
  }
});