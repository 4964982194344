require("./base.scss");
import "custom-event-polyfill";
import "element-closest-polyfill";

require("core-js");
require("clip-path");
require("@fortawesome/fontawesome-free");
require("moment");

require("chartist");
window.d3 = require("d3");
window.c3 = require("c3");
require("./js/widgets/charts.js");
require("../react/js/script/expand-menu.js");


import * as Sentry from "@sentry/browser";
global.Sentry = Sentry

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://fbe6d2ad73204c2e8d847b0d72af5954@sentry.io/1508988"
  });
}
